/* Schedule.css */
.schedule-container {
  max-width: 1200px; /* Limit the width of the calendar */
  margin: 0 auto; /* Center the calendar horizontally */
  padding: 20px; /* Add some padding around the calendar */
}

.rbc-calendar {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Make sure the calendar doesn't exceed the container's width */
  margin: 0 auto;
}

.rbc-toolbar {
  background-color: #282c34;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.rbc-toolbar button {
  background-color: #fc94f5;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.rbc-toolbar button:hover {
  background-color: #e784d0;
}

.rbc-month-view {
  border-radius: 0 0 10px 10px;
}

.rbc-day-bg {
  background-color: #fff;
}

.rbc-today {
  background-color: #fc94f5 !important;
}

.rbc-event {
  background-color: #282c34 !important;
  color: white !important;
  border-radius: 5px;
  padding: 5px;
}

/* Add media queries to ensure it adjusts for smaller screen sizes */
@media (max-width: 768px) {
  .schedule-container {
    padding: 10px;
  }

  .rbc-calendar {
    width: 100%; /* Ensure the calendar doesn't stretch on mobile */
  }
}
