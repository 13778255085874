/* src/pages/About.css */

.about-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
  
  .about-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .about-logo {
    flex-shrink: 0;
    margin-left: auto; /* Push the logo to the right */
  }
  
  .about-logo img {
    max-width: 300px; /* Adjust the size as needed */
    height: auto;
  }
  
  /* New style for mobile responsiveness */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column; /* Stack elements vertically on small screens */
      align-items: center; /* Center elements horizontally */
    }
  
    .about-content {
      padding-right: 0; /* Remove right padding */
      margin-bottom: 20px; /* Add margin for spacing */
    }
  
    .about-logo {
      margin-left: 0; /* Remove left margin */
    }
  
    .about-logo img {
      max-width: 80%; /* Reduce the size for better fit on mobile */
    }
  }
  
  .coach-image {
    margin-top: 20px; /* Adjust the spacing as needed */
    text-align: center; /* Center the image horizontally */
  }
  
  .coach-image img {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto;
  }
  
  h1 {
    font-family: 'Metamorphous', cursive;
    font-size: 2.5em;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  /* About.css */
.editable-input, .editable-textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.editable-textarea {
  resize: none;
  height: 150px; /* Adjust as needed */
}
