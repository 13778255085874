/* src/components/Footer.css */

.footer {
    background-color: #282c34;
    color: white;
    padding: 20px 0;
    text-align: center;
    margin-top: auto;
  }
  
  .footer p {
    margin: 0;
    padding: 10px 0;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-links li {
    display: inline;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  