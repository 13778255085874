.header {
  background-color: #282c34;
  color: white;
  padding: 20px 0;
}

.nav {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo-container {
  flex-shrink: 0;
}

.nav-logo {
  height: 50px;
}

.nav-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 20px; /* Always keep it aligned to the right */
  top: 20px;
  z-index: 10;
  display: none; /* Hide by default */
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-item {
  text-transform: uppercase;
  position: relative;
}

.nav-item a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
}

/* Mobile Menu */
@media (max-width: 768px) {
  .nav-toggle {
    display: block; /* Show only in mobile view */
  }

  .nav-list {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    background-color: #282c34;
    position: absolute;
    top: 70px; /* Adjust based on the height of your header */
    left: 0;
    right: 0;
  }

  .nav-list-open {
    display: flex;
  }
}

/* Hide the nav-list in desktop view if the screen width is larger than 768px */
@media (min-width: 769px) {
  .nav-list {
    display: flex !important;
  }
}
