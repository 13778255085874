/* src/pages/Home.css */

@import url('https://fonts.googleapis.com/css2?family=Metamorphous&display=swap');

.home-container {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full height */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  transition: background-image 1s ease-in-out; /* Smooth transition */
}

.text-box {
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  padding: 20px 80px;
  border-radius: 10px;
  transform: skew(-5deg) translate(400px, 350px); /* Adjusted to move right and up */
}

.text-box h1 {
  font-family: 'Metamorphous', cursive;
  font-size: 3em;
  margin: 0;
}

.text-box p {
  font-size: 1.5em;
  margin: 10px 0;
}

.read-more-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fc94f5; /* Claymore color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  position: relative;
  transform: skew(-10deg); /* Adjusted to match the background skew */
}

.read-more-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fc94f5; /* Claymore color */
  z-index: -1;
  transform: skew(10deg);
  border-radius: 5px;
}

.read-more-link:hover {
  background-color: #e083d1; /* A shade darker for hover */
}

.read-more-link:hover::before {
  background-color: #e083d1; /* A shade darker for hover */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .text-box {
    padding: 15px 40px;
    transform: skew(-5deg) translate(20px, -20px); /* Adjust for smaller screens */
  }

  .text-box h1 {
    font-size: 2.5em;
  }

  .text-box p {
    font-size: 1.2em;
  }

  .read-more-link {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .text-box {
    padding: 10px 20px;
    transform: skew(-5deg) translate(10px, -10px); /* Adjust for very small screens */
  }

  .text-box h1 {
    font-size: 2em;
  }

  .text-box p {
    font-size: 1em;
  }

  .read-more-link {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}
