/* src/App.css */

html, body {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer p {
  margin: 0;
  padding: 10px 0;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-links li {
  display: inline;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

@font-face {
  font-family: 'Viking';
  src: url('/src/assets/fonts/VIKING-N.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Viking', 'Times New Roman', serif;
}
